var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { staticClass: "d-flex justify-content-center" },
        [
          _c(
            "v-col",
            {
              staticClass: "p-0",
              attrs: { cols: "12", xl: "8", lg: "9", md: "11" },
            },
            [
              _c(
                "v-card",
                {
                  staticClass: "mt-5",
                  staticStyle: {
                    "max-height": "900px",
                    "overflow-y": "auto",
                    "overflow-x": "hidden",
                  },
                  attrs: { flat: _vm.$vuetify.breakpoint.mobile },
                  on: {
                    scroll: function ($event) {
                      return _vm.delayedscroll()
                    },
                  },
                },
                [
                  _c(
                    "v-container",
                    { attrs: { fluid: "" } },
                    [
                      _vm.rows.length == 0
                        ? _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c(
                                    "v-card-text",
                                    { staticClass: "text-center" },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            size: "100",
                                            color: "grey lighten-2",
                                          },
                                        },
                                        [_vm._v("mdi-account-group-outline")]
                                      ),
                                      _c("h3", { staticClass: "mt-3" }, [
                                        _vm._v("Henüz içerik eklenmemiş."),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _c(
                            "v-row",
                            { staticClass: "mt-3" },
                            _vm._l(_vm.rows, function (row) {
                              return _c(
                                "v-col",
                                {
                                  key: row.Id,
                                  staticClass: "p-0",
                                  attrs: { cols: "12" },
                                },
                                [
                                  _c(
                                    "v-sheet",
                                    {
                                      staticClass: "p-4",
                                      attrs: {
                                        shaped: "",
                                        "min-height": "200",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pt-2",
                                              attrs: { cols: "auto" },
                                            },
                                            [
                                              _c(
                                                "v-avatar",
                                                {
                                                  attrs: {
                                                    size: "32",
                                                    rounded: "",
                                                  },
                                                },
                                                [
                                                  row.PersonnelImage != null
                                                    ? _c("v-img", {
                                                        attrs: {
                                                          src: row.PersonnelImage,
                                                          contain: "",
                                                        },
                                                      })
                                                    : _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { large: "" },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "mdi-account-circle"
                                                          ),
                                                        ]
                                                      ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              attrs: {
                                                cols: _vm.$vuetify.breakpoint
                                                  .mobile
                                                  ? "10"
                                                  : "auto grow",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass:
                                                        "pb-0 pt-2 px-0",
                                                      attrs: { cols: "10" },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "font-weight-bold text-break",
                                                          staticStyle: {
                                                            "font-size": "14px",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              row.Content.Title
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass:
                                                        "py-0 d-flex justify-content-end",
                                                      attrs: { cols: "2" },
                                                    },
                                                    [
                                                      row.Content
                                                        .ContentTypeId != 4
                                                        ? _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                color:
                                                                  "primary",
                                                                disabled:
                                                                  _vm.saving,
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.downloadAll(
                                                                      row
                                                                        .Content
                                                                        .ContentFiles
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "mdi-cloud-download-outline "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "pt-0 pl-0",
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-muted",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                row.Content
                                                                  .CreatedBy
                                                              )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      row.Content.Description &&
                                      row.Content.ContentTypeId != 4
                                        ? _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "pt-0",
                                                  attrs: { cols: "12" },
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass: "mr-1",
                                                      attrs: { small: "" },
                                                    },
                                                    [_vm._v("mdi-text")]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "font-size": "13px",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            row.Content
                                                              .Description
                                                          )
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { staticClass: "p-0" },
                                            [
                                              row.Content.ContentTypeId == 1 &&
                                              row.Content.ContentFiles.length >
                                                0
                                                ? _c("v-img", {
                                                    attrs: {
                                                      src: row.Content
                                                        .ContentFiles[0]
                                                        .FilePublicUrl,
                                                      "max-height": _vm.$vuetify
                                                        .breakpoint.mobile
                                                        ? 320
                                                        : 550,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        return _vm.openUrl(
                                                          row.Content
                                                            .ContentFiles[0]
                                                            .FilePublicUrl
                                                        )
                                                      },
                                                    },
                                                  })
                                                : row.Content.ContentTypeId ==
                                                    2 &&
                                                  row.Content.ContentFiles
                                                    .length > 0
                                                ? _c(
                                                    "video",
                                                    {
                                                      staticStyle: {
                                                        "max-height": "400px",
                                                      },
                                                      attrs: {
                                                        src: row.Content
                                                          .ContentFiles[0]
                                                          .FilePublicUrl,
                                                        controls: "",
                                                        name: "media",
                                                      },
                                                    },
                                                    [
                                                      _c("source", {
                                                        attrs: {
                                                          src:
                                                            row.Content
                                                              .ContentFiles[0]
                                                              .FilePublicUrl +
                                                            "#t=0.1",
                                                          type: row.Content
                                                            .ContentFiles[0]
                                                            .FileType,
                                                        },
                                                      }),
                                                    ]
                                                  )
                                                : row.Content.ContentTypeId == 3
                                                ? _c(
                                                    "b-carousel",
                                                    {
                                                      attrs: {
                                                        controls: "",
                                                        indicators: "",
                                                        interval: 0,
                                                      },
                                                    },
                                                    [
                                                      _vm._l(
                                                        row.Content.ContentFiles.filter(
                                                          (s) =>
                                                            s.FileType.includes(
                                                              "image"
                                                            )
                                                        ),
                                                        function (item) {
                                                          return _c(
                                                            "b-carousel-slide",
                                                            {
                                                              key: item.Id,
                                                              attrs: {
                                                                "img-src":
                                                                  item.FilePublicUrl,
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                    return _vm.openUrl(
                                                                      item.FilePublicUrl
                                                                    )
                                                                  },
                                                              },
                                                            }
                                                          )
                                                        }
                                                      ),
                                                      _vm._l(
                                                        row.Content.ContentFiles.filter(
                                                          (s) =>
                                                            s.FileType.includes(
                                                              "video"
                                                            )
                                                        ),
                                                        function (item) {
                                                          return _c(
                                                            "b-carousel-slide",
                                                            {
                                                              key: item.Id,
                                                              staticStyle: {
                                                                "max-height":
                                                                  "400px",
                                                              },
                                                              attrs: {
                                                                "img-blank": "",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "video",
                                                                {
                                                                  staticStyle: {
                                                                    "max-height":
                                                                      "400px",
                                                                  },
                                                                  attrs: {
                                                                    src: item.FilePublicUrl,
                                                                    controls:
                                                                      "",
                                                                    autobuffer:
                                                                      "",
                                                                    name: "media",
                                                                  },
                                                                },
                                                                [
                                                                  _c("source", {
                                                                    attrs: {
                                                                      src:
                                                                        item.FilePublicUrl +
                                                                        "#t=0.1",
                                                                      type: item.FileType,
                                                                    },
                                                                  }),
                                                                ]
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                    ],
                                                    2
                                                  )
                                                : row.Content.ContentTypeId == 4
                                                ? _c("v-container", [
                                                    _c(
                                                      "h4",
                                                      {
                                                        staticClass:
                                                          "text-break font-weight-medium",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              row.Content
                                                                .Description
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ])
                                                : row.Content.ContentTypeId == 5
                                                ? _c(
                                                    "v-container",
                                                    _vm._l(
                                                      row.Content.ContentFiles.filter(
                                                        (s) =>
                                                          s.FileType.includes(
                                                            "pdf"
                                                          )
                                                      ),
                                                      function (item) {
                                                        return _c(
                                                          "div",
                                                          { key: item.Id },
                                                          [
                                                            _c(
                                                              "object",
                                                              {
                                                                staticClass:
                                                                  "pt-3 px-3",
                                                                staticStyle: {
                                                                  "min-height":
                                                                    "350px",
                                                                },
                                                                attrs: {
                                                                  data: item.FilePublicUrl,
                                                                  type: "application/pdf",
                                                                  width: "100%",
                                                                  height:
                                                                    "100%",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "m-5",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "PDF görüntülenemiyor. "
                                                                    ),
                                                                    _c(
                                                                      "a",
                                                                      {
                                                                        attrs: {
                                                                          href: item.FilePublicUrl,
                                                                          target:
                                                                            "_blank",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "PDF'i indirmek için tıklayınız."
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "d-flex",
                                              attrs: { cols: "12" },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: { color: "red" },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      return _vm.like(
                                                        row.Content
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        row.Content.IsLiked
                                                          ? "mdi-heart"
                                                          : "mdi-heart-outline"
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              row.Content.HasComment
                                                ? _c(
                                                    "v-icon",
                                                    {
                                                      staticClass: "ml-2",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.openComments(
                                                            row.ContentId
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " mdi-comment-outline "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c("v-spacer"),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("fromNow")(
                                                      row.Content.CreatedDate
                                                    )
                                                  )
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "py-0 d-flex justify-content-between",
                                              attrs: { cols: "12" },
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "mt-1" },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass: "mr-1",
                                                      attrs: { small: "" },
                                                    },
                                                    [_vm._v("mdi-shape")]
                                                  ),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        row.Content
                                                          .ContentTypeName
                                                      ) +
                                                      " "
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                [
                                                  row.Content.TwitterLink !=
                                                    null &&
                                                  row.Content.TwitterLink
                                                    .length > 0
                                                    ? _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            color: "#1DA1F2",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.openUrl(
                                                                row.Content
                                                                  .TwitterLink
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("mdi-twitter ")]
                                                      )
                                                    : _vm._e(),
                                                  row.Content.FacebookLink !=
                                                    null &&
                                                  row.Content.FacebookLink
                                                    .length > 0
                                                    ? _c(
                                                        "v-icon",
                                                        {
                                                          staticClass: "ml-2",
                                                          attrs: {
                                                            color: "#3B5998",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.openUrl(
                                                                row.Content
                                                                  .FacebookLink
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "mdi-facebook "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  row.Content.InstagramLink !=
                                                    null &&
                                                  row.Content.InstagramLink
                                                    .length > 0
                                                    ? _c(
                                                        "v-icon",
                                                        {
                                                          staticClass: "ml-2",
                                                          attrs: {
                                                            color: "#d62976",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.openUrl(
                                                                row.Content
                                                                  .InstagramLink
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " mdi-instagram "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm.showComments &&
                                      row.Content.ShowComments
                                        ? _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "py-0",
                                                  attrs: { cols: "12" },
                                                },
                                                [
                                                  _c(
                                                    "v-expand-transition",
                                                    [
                                                      !_vm.loadingComments
                                                        ? _c(
                                                            "v-list",
                                                            {
                                                              attrs: {
                                                                "two-line": "",
                                                                tile: "",
                                                                dense: "",
                                                              },
                                                            },
                                                            [
                                                              _vm._l(
                                                                row.Content
                                                                  .Comments,
                                                                function (
                                                                  comment
                                                                ) {
                                                                  return _c(
                                                                    "v-list-item",
                                                                    {
                                                                      key: comment.Id,
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-avatar",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              size: "28",
                                                                              rounded:
                                                                                "",
                                                                            },
                                                                        },
                                                                        [
                                                                          comment.ImageUrl !=
                                                                          null
                                                                            ? _c(
                                                                                "v-img",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      src: comment.ImageUrl,
                                                                                      contain:
                                                                                        "",
                                                                                    },
                                                                                }
                                                                              )
                                                                            : _c(
                                                                                "v-icon",
                                                                                [
                                                                                  _vm._v(
                                                                                    "mdi-account-circle"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _c(
                                                                        "v-list-item-content",
                                                                        {
                                                                          staticClass:
                                                                            "ml-2",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            [
                                                                              _c(
                                                                                "strong",
                                                                                {
                                                                                  staticClass:
                                                                                    "mr-1",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " " +
                                                                                      _vm._s(
                                                                                        comment.UserName
                                                                                      )
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    comment.Text
                                                                                  )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "text-muted",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    _vm._f(
                                                                                      "formattedDateTime"
                                                                                    )(
                                                                                      comment.CreatedDate
                                                                                    )
                                                                                  )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "v-list-item-action",
                                                                        [
                                                                          comment.IsDeletable
                                                                            ? _c(
                                                                                "v-btn",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      icon: "",
                                                                                      small:
                                                                                        "",
                                                                                    },
                                                                                  on: {
                                                                                    click:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.deleteComment(
                                                                                          comment
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-icon",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          color:
                                                                                            "red",
                                                                                          small:
                                                                                            "",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "mdi-delete"
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              )
                                                                            : _vm._e(),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                }
                                                              ),
                                                              _c(
                                                                "v-text-field",
                                                                {
                                                                  staticClass:
                                                                    "mx-2 mt-2",
                                                                  attrs: {
                                                                    filled: "",
                                                                    rounded: "",
                                                                    clearable:
                                                                      "",
                                                                    dense: "",
                                                                    "hide-details":
                                                                      "",
                                                                    "append-outer-icon":
                                                                      "mdi-send",
                                                                    placeholder:
                                                                      "yorum ekle...",
                                                                  },
                                                                  on: {
                                                                    "click:append-outer":
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.addComment(
                                                                          row.Content
                                                                        )
                                                                      },
                                                                    keydown:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        if (
                                                                          !$event.type.indexOf(
                                                                            "key"
                                                                          ) &&
                                                                          _vm._k(
                                                                            $event.keyCode,
                                                                            "enter",
                                                                            13,
                                                                            $event.key,
                                                                            "Enter"
                                                                          )
                                                                        )
                                                                          return null
                                                                        return _vm.addComment(
                                                                          row.Content
                                                                        )
                                                                      },
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      row
                                                                        .Content
                                                                        .NewComment,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          row.Content,
                                                                          "NewComment",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "row.Content.NewComment",
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            2
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "v-row",
                                        { staticClass: "mx-3" },
                                        [
                                          _c("v-divider", {
                                            staticClass: "m-5",
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }